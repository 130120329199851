<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <script-engines-select
        :key="`${step.id}-scriptEngine`"
        :default-value="properties.scriptEngine"
        step-label="storage"
        :readonly="!canEdit"
        required
        @change="handleChange('scriptEngine', $event)"
      />
    </v-col>

    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedStorageActions"
        :default-value="properties.action"
        step-label="storage"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col cols="12">
      <div class="d-flex">
        <v-text-field
          :key="`${step.id}-key`"
          v-model="properties['key']"
          outlined
          dense
          class="required-asterisk"
          data-cy="storage-key"
          :label="$lang.labels.key"
          :readonly="!canEdit"
          :rules="[v => !!v || $lang.labels.required]"
        />
        <v-btn
          text
          color="primary"
          :disabled="!canEdit || !$route.params.id || !properties['key']"
          @click="openStorage(properties['key'])"
        >
          {{ $lang.labels.open }}
        </v-btn>
      </div>
    </v-col>

    <v-col v-if="properties.action === 'GET'" cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.fields.targetObject"
        outlined
        dense
        class="required-asterisk"
        data-cy="storage-targetObject"
        :label="$lang.labels.targetObject"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
      />
    </v-col>

    <v-col v-if="properties.action === 'UPSERT'" cols="12">
      <v-text-field
        :key="`${step.id}-expiresIn`"
        v-model="properties.fields.expiresIn"
        outlined
        dense
        class="required-asterisk"
        data-cy="storage-expiresIn"
        :label="$lang.labels.expiresIn"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
      />
      <v-text-field
        :key="`${step.id}-value`"
        v-model="properties.fields.value"
        outlined
        dense
        class="required-asterisk"
        data-cy="storage-value"
        :label="$lang.labels.value"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
      />
    </v-col>
  </v-row>
</template>

<script>
import { ScriptEnginesSelect, ActionSelect } from './components'
import StepMixin from './StepMixin'

import { STORAGE_ACTIONS } from './constants'
import {
  getStoragesUsingGET as getStorage
} from '@/utils/api'
import { mapActions } from 'vuex'

export default {
  name: 'StorageStep',
  components: {
    ScriptEnginesSelect,
    ActionSelect
  },
  mixins: [StepMixin],
  computed: {
    formattedStorageActions() {
      return STORAGE_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.action) {
      this.properties.action = 'GET'
      if (!this.properties.fields) this.properties.fields = {
        targetObject: ''
      }
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    handleChange(key, val) {
      if (key === 'action') {
        this.onChangeStorageAction(val)
      }
      
      if (key.includes('.')) {
        const { nestedObj, lastKey } = getNestedPropertyForSetting(this.properties, key)

        this.$set(nestedObj, lastKey, val)

        return
      }
      this.$set(this.properties, key, val)
    },
    onChangeStorageAction(val) {
      if (val === 'GET') {
        this.properties.fields = {
          targetObject: ''
        }
      } else {
        this.properties.fields = {
          expiresIn: '',
          value: ''
        }
      }
    },
    openStorage(key) {
      if (key && this.$route.params.id) {
        const replacedKey = key.replace(/'/g, '')

        getStorage({ processId: this.$route.params.id, key: replacedKey }).then((res) => {
          const findStorageId = res.data.data.items.find((x) => x.key === replacedKey)

          if (findStorageId) {
            window.open(`/${localStorage.selectedLanguage || 'en'}/storage/edit/${findStorageId.id}`, '_blank')
          } else {
            this.addSnackbar({
              message: this.$lang.errors.notFound,
              timeout: 5000,
              color: 'error'
            })
          }
        })
          .catch((err) => {
            this.addSnackbar({
              message: err,
              timeout: 5000,
              color: 'error'
            })
          })
      }
    }
  }
}
</script>
